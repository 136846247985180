div.editor-footer {
    min-height: 2rem;
    display: flex;
    align-items: center;

    background-color: black;

    padding-left: .8em;

    color: white;
    font-size: 0.8rem;
}
