p.contacts {
    margin-left: .5rem;
}

p.contacts > a {
    display: inline-block;
    margin-bottom: .5rem;
    text-decoration: underline;
}

p.contacts > a:last-child {
    margin-bottom: 0;
}

img.contact-icon {
    width: 1.75em;
    margin-right: .5em;
}