div.sidebar {
    min-width: 320px;

    overflow-x: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: var(--darkBackgroundColor);

    transition: margin-left;
    transition-duration: .5s;
}

@media (max-width: 640px) {
    div.sidebar {
        margin-left: -100vw;
        min-width: 100vw;
        max-width: 100vw;
    }

    div.sidebar.mobile-menu-open {
        margin-left: 0;
    }
}

