div.editor {
    width: 100vw;
    height: 100vh;

    margin: auto;

    display: flex;
    flex-direction: column;

    overflow-y: auto;
    overflow-x: hidden;

    background-color: var(--darkBackgroundLighterColor);
}

div.editor-body {
    display: flex;

    overflow-y: auto;

    flex-grow: 1;
}

div.editor-center {
    overflow-y: auto;
    width: 100%;

    display: flex;
    flex-direction: column;
}
