@import url(https://fonts.googleapis.com/css?family=Source+Code+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu|Ubuntu+Mono&display=swap);
/* 'Source code pro' */

body {
  color: var(--textColor);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Ubuntu', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
    --darkColor: #000000;
    --darkBackgroundColor: #202020;/*#181915;*/
    --darkBackgroundLighterColor: #272822;
    --textColor: #CCC;
    --textDarkerColor: #888888;
    --textDarkerDarkerColor: #555555;
}

div.editor {
    width: 100vw;
    height: 100vh;

    margin: auto;

    display: flex;
    flex-direction: column;

    overflow-y: auto;
    overflow-x: hidden;

    background-color: var(--darkBackgroundLighterColor);
}

div.editor-body {
    display: flex;

    overflow-y: auto;

    flex-grow: 1;
}

div.editor-center {
    overflow-y: auto;
    width: 100%;

    display: flex;
    flex-direction: column;
}

div.editor-header {
    height: 2.5rem;
    padding: .5em;
    display: flex;

    text-align: center;

    background-color: black;
    color: white;
}

div.mobile-menu-toggle-trigger {
    cursor: pointer;
}

@media (min-width: 640px) {
    div.mobile-menu-toggle-trigger {
        display: none;
    }
}





div.toggle {
    width: 2rem;
    height: 2rem;

    margin-top: .15rem;
    position: relative;

    cursor: pointer;
}

div.toggle span {
    display: block;
    position: absolute;

    height: .25rem;
    width: 100%;

    background: var(--textColor);
    border-radius: 9px;
    left: 0;

    -webkit-transform: rotate(0deg);

            transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
}

div.toggle span:nth-child(1) {
    top: 0;
}

div.toggle span:nth-child(2) {
    top: .5rem;
}

div.toggle span:nth-child(3) {
    top: 1rem;
}

div.toggle.open span:nth-child(1) {
    top: .5rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

div.toggle.open span:nth-child(2) {
    left: -3rem;
}

div.toggle.open span:nth-child(3) {
    top: .5rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

div.editor-footer {
    min-height: 2rem;
    display: flex;
    align-items: center;

    background-color: black;

    padding-left: .8em;

    color: white;
    font-size: 0.8rem;
}

div.sidebar {
    min-width: 320px;

    overflow-x: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: var(--darkBackgroundColor);

    -webkit-transition: margin-left;

    transition: margin-left;
    -webkit-transition-duration: .5s;
            transition-duration: .5s;
}

@media (max-width: 640px) {
    div.sidebar {
        margin-left: -100vw;
        min-width: 100vw;
        max-width: 100vw;
    }

    div.sidebar.mobile-menu-open {
        margin-left: 0;
    }
}


img.file-icon {
    margin-right: 0.2rem;
}

span.file-icon-and-name {
    cursor: pointer;
    white-space: nowrap;
}
div.folder ul {
    list-style: none;
    padding-left: .5rem;
    max-height: 0;
    overflow-y: hidden;

    -webkit-transition-property: max-height;

    transition-property: max-height;
    -webkit-transition-duration: .5s;
            transition-duration: .5s;
}

div.folder.expanded > ul {
    max-height: 500px; /* TODO: Find another solution to animate the change of height */
}

div.folder li {
    padding: .25rem .12rem;
}

img.folder-toggler {
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: .5s;
            transition-duration: .5s;
}

div.folder.expanded > div.folder-name-and-toggler img{
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

div.folder-name-and-toggler {
    cursor: pointer;
}

p.contacts {
    margin-left: .5rem;
}

p.contacts > a {
    display: inline-block;
    margin-bottom: .5rem;
    text-decoration: underline;
}

p.contacts > a:last-child {
    margin-bottom: 0;
}

img.contact-icon {
    width: 1.75em;
    margin-right: .5em;
}
div.tab {
    display: flex;
    padding: 4px .5rem;
    background-color: var(--darkBackgroundColor);
    cursor: pointer;
    white-space: nowrap;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

}

div.tab img {
    margin-left: .4rem;
}

div.tab.current-tab {
    background-color: var(--darkBackgroundLighterColor);
}

div.tabs-container {
    display: flex;
    background-color: #070802;
    overflow-x: auto;
    min-height: 2.125em;
}
div.file-viewer {
    overflow-y: auto;
}

div.file-viewer p {
    text-align: justify;

    font-size: 1.1rem;
}

div.file-content-container {
    padding: 1em;
}

div.file-content-container h2 {
    margin-top: 1em;
}

div.file-content-container *:before {
    color: green;
}

div.file-content-container h1:before {
    content: '# ';
}

div.file-content-container h1 {
    font-size: 2rem;
}

div.file-content-container h2:before {
    content: '## ';
}

div.file-content-container h2 {
    font-size: 1.5rem;
}


div.file-content-container h3:before {
    content: '### ';
}


div.file-content-container h3 {
    font-size: 1.3rem;
}

div.file-content-container img {
    max-width: 100%;
    display: block;
    margin: 2em auto;
}


div.file-content-container blockquote {
    margin-left: .5em;
    box-sizing: border-box;
    padding-left: 1em;
    border-left: 10px solid gray;
}

div.history-list h3 {
    margin-top: 1em;
}

div.history-list h3 + p,
div.history-list h3 + div.history-list-item-multiple-paragraphs {
    position: relative;
    padding-left: 2em;
}

div.history-list h3:before {
    content: url(/static/media/point.8de961ac.svg);
    width: 1em;
    top: 0.5em;

    color: white;
    margin-right: 6px;
}

div.history-list h3 + p:before,
div.history-list h3 + div.history-list-item-multiple-paragraphs:before {
    content: "";
    position: absolute;
    left: .6125em;
    top: -0.96em;
    height: calc(100% + 2.35em);
    border: 1px solid var(--textDarkerDarkerColor);
}

div.history-list h3:last-of-type + p:before,
div.history-list h3:last-of-type + div.history-list-item-multiple-paragraphs:before{
    height: 0;
    border: none;
}
div.open-from-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    font-size: 2rem;

    max-width: 60vmin;
}

div.open-from-sidebar img {
    width: 60vmin;
    max-width: 20rem;
}

div.open-from-sidebar p {
    text-align: center;
}
div.loading-content {
    color: var(--textColor);
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: auto;
    font-size: 2rem;
}

div.loading-content-spinner {
    width: 10rem;
    height: 10rem;
    border-width: 1rem;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
}

div.editor-body {
    font-family: 'Ubuntu Mono', monospace;
}

