div.tab {
    display: flex;
    padding: 4px .5rem;
    background-color: var(--darkBackgroundColor);
    cursor: pointer;
    white-space: nowrap;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

}

div.tab img {
    margin-left: .4rem;
}

div.tab.current-tab {
    background-color: var(--darkBackgroundLighterColor);
}
