div.editor-header {
    height: 2.5rem;
    padding: .5em;
    display: flex;

    text-align: center;

    background-color: black;
    color: white;
}

div.mobile-menu-toggle-trigger {
    cursor: pointer;
}

@media (min-width: 640px) {
    div.mobile-menu-toggle-trigger {
        display: none;
    }
}



