
div.history-list h3 {
    margin-top: 1em;
}

div.history-list h3 + p,
div.history-list h3 + div.history-list-item-multiple-paragraphs {
    position: relative;
    padding-left: 2em;
}

div.history-list h3:before {
    content: url("../../../../../icons/point.svg");
    width: 1em;
    top: 0.5em;

    color: white;
    margin-right: 6px;
}

div.history-list h3 + p:before,
div.history-list h3 + div.history-list-item-multiple-paragraphs:before {
    content: "";
    position: absolute;
    left: .6125em;
    top: -0.96em;
    height: calc(100% + 2.35em);
    border: 1px solid var(--textDarkerDarkerColor);
}

div.history-list h3:last-of-type + p:before,
div.history-list h3:last-of-type + div.history-list-item-multiple-paragraphs:before{
    height: 0;
    border: none;
}